<template>
  <div class='shipper'>
    <!-- 送货人 -->
    <div class='globle_border' v-loading="loading">
      <div class="search">
        <el-input v-model="search" placeholder="请输入姓名" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="handleAdd">新增</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="manName" label="姓名"></el-table-column>
          <el-table-column prop="telNum" label="联系电话"></el-table-column>
          <el-table-column prop="createdTime" label="创建时间"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="160">
            <template slot-scope="scope">
              <el-button class="btn" type="" size="" plain @click="handleEdit(scope.$index, scope.row)">修改</el-button>
              <el-button class="btn" type="danger" size="" plain @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <el-dialog :title="isAdd ? '新增' : '修改'" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="manName">
              <el-input v-model="ruleForm.manName" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="联系电话" prop="telNum">
              <el-input v-model="ruleForm.telNum" placeholder="请输入联系电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
function createInitRuleForm () {
  return {
    id: '',
    manName: '',
    telNum: '',
  }
}
export default {
  data () {
    var checkTel = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('联系电话不能为空'));
      }
      if (!/^1[345789]\d{9}$/.test(value)) {
        callback(new Error('联系电话格式不正确'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      search: '',
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      dialogVisible: false,
      isAdd: true,
      ruleForm: createInitRuleForm(),
      rules: {
        manName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        telNum: [{ required: true, validator: checkTel, trigger: 'blur' }],
      },
      departmentList: [],
      roleList: [],
    }
  },
  created () {
    this.getSupplierDeliveryManList()
  },
  methods: {
    // 列表
    getSupplierDeliveryManList () {
      this.loading = true
      this.$axios.get(this.$api.supplierDeliveryManList, {
        params: {
          manName: this.search,
          page: this.currentPage,
          pageSize: this.pageSize
        }
      }).then((res) => {
        this.tableData = res.data.result.list
        this.totalItemsCount = res.data.result.totalCount
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    // 搜索
    onSearch () {
      this.tableData = []
      this.currentPage = 1
      this.getSupplierDeliveryManList()
    },
    // 新增（对话框）
    handleAdd () {
      this.isAdd = true
      this.dialogVisible = true
    },
    // 修改（对话框）
    handleEdit (index, row) {
      this.isAdd = false;
      this.$axios.get(this.$api.supplierDeliveryManQuery, {
        params: {
          id: row.id
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.ruleForm = res.data.result;
          this.dialogVisible = true;
        } else {
          this.dialogVisible = false;
        }
      }).catch(() => {
        this.dialogVisible = false;
      });
    },
    // 删除
    handleDelete (index, row) {
      this.$confirm('此操作将永久删除该人员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.post(this.$api.supplierDeliveryManDelete, {
          id: row.id
        }).then((res) => {
          if (res.data.code == 100) {
            this.$message.success(res.data.desc)
          }
          this.getSupplierDeliveryManList()
        }).catch(() => { })
      }).catch(() => { });
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.tableData = []
      this.pageSize = e
      this.getSupplierDeliveryManList()
    },
    // 换页
    handleCurrentChange (e) {
      this.tableData = []
      this.currentPage = e
      this.getSupplierDeliveryManList()
    },
    // 关闭对话框
    onCancel () {
      this.dialogVisible = false
      this.resetForm()
    },
    // 提交表单
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let api = this.isAdd ? this.$api.supplierDeliveryManAdd : this.$api.supplierDeliveryManEdit
          this.$axios.post(api, this.ruleForm).then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc);
              this.getSupplierDeliveryManList();
              this.dialogVisible = false;
              this.resetForm();
            }
          });
        } else {
          this.$message.error('提交失败');
          return false;
        }
      });
    },
    // 重置校验
    resetForm () {
      this.ruleForm = createInitRuleForm()
      this.$refs.ruleForm.resetFields();
    },
  },
}
</script>

<style scoped lang='scss'>
.shipper {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;

      /deep/ .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .disabled_user_account {
    width: 255px;
    color: #C0C4CC;
    padding: 0 15px;
    border-radius: 10px;
    background-color: #F5F7FA;
    box-sizing: border-box;
    cursor: not-allowed;
  }
}
</style>
